body, html, #root {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}

#root {
    font-size: 13px;
}

#bg-video {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(65, 76, 150, 0);
}

.info-modal {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.info-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 70%;
    background: #F6F7FC;
    border-radius: 40px;
    padding: 20px;
}

.info-modal-logos {
    display: flex;
    width: 90%;
}

.info-modal-text {
    width: 90%;
}

.info-modal-text-intro {
    background: #414C96;
    border-radius: 10px;
    color: white;
    font-size: 1.3em;
    padding: 20px;
}

.info-modal-text-rules {
    background: #BACEDB;
    border-radius: 10px;
    font-size: 1.3em;
    padding: 10px;
    margin-top: 20px;
}

.info-modal-close {
    cursor: pointer;
    display: flex;
    padding: 5px;
    margin: 5px;
    border: 1px solid #D1D1D1;
    border-radius: 10px;
    justify-content: center;
    font-size: 1.3em;
    width: 90%;
}

.info-modal-close:hover {
    border: 1px solid #02CC9A;
    background: #02CC9A;
    color: #F6F7FC;
}

.debug {
    display: flex;
    color: red;
    background: #D1D1D1;
    align-items: center;
}

.game {
    display: flex;
    height: 960px;
    max-width: 610px;
    min-width: 510px;
    width: 100%;
    flex-direction: column;
    border-radius: 40px;
}

.game-over {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 40px;
    background: #172950;
}

.game-over-header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.game-over-content {
    display: flex;
    flex-direction: column;
    flex: 7;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 40px;
    width: 100%;
}

.game-over-icon {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.game-over-text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.3em;
}

.game-over-bottom {
    display: flex;
    flex-direction: column;
    flex: 3;
    border-radius: 40px;
    background: #172950;
    justify-content: space-around;
    align-items: center;
}

.welcome {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 40px;
    background: #F6F7FC;
}

.welcome-top {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    flex: 9;
    border-radius: 40px;
    padding: 20px;
}

.welcome-logo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #172950;
    font-size: 1.6em;
    margin-top: 5em;
    animation: fadein 3s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.welcome-tutorial {
    display: flex;
    border-radius: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex: 8;
    background: #172950;
}

.welcome-play {
    display: flex;
    border-radius: 40px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    flex: 8;
    background: #172950;
}

.welcome-tutorial-text {
    width: 70%;
    font-size: 1.3em;
    color: #F6F7FC;
}


.high-score {
    display: flex;
    width: 65%;
    justify-content: space-between;
    color: #FFFFFF;
    margin-top: 1em;
    font-size: 1.3em;
}

.play-button {
    cursor: pointer;
    text-align: center;
    background: #02CC9A;
    color: #172950;
    width: 60%;
    font-size: 1.3em;
    border-radius: 10px;
    padding: 5px;
}

.welcome-tutorial-ready {
    cursor: pointer;
    text-align: center;
    background: #02CC9A;
    color: #172950;
    width: 70%;
    font-size: 1.3em;
    border-radius: 7px;
    padding: 5px;
    margin-top: 20px;
}

.welcome-tutorial-ready:hover, .play-button:hover {
    background: #02CC9A;
    color: #172950;
}

.share-button {
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    width: 60%;
    font-size: 1.3em;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #02CC9A;
}

.mobile {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    background: #051C41;
    border-radius: 40px;
}

.screen {
    display: flex;
    flex-direction: column;
    flex: 8;
    justify-content: center;
    background: #F6F7FC;
    align-items: center;
    border-radius: 35px;
}

.header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    background: #051C41;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 40px 40px 0 0;
}

.header-item {
    display: flex;
    height: 5.3em;
    width: 5.3em;
    align-items: center;
}

.quarter {
    display: flex;
}

.satisfaction {
    display: flex;
}

.valuation {
    display: flex;
    flex-direction: column;
}

.valuation-top {
    display: flex;
    font-size: 1.16em;
    white-space: nowrap;
}

.valuation-added {
    display: flex;
    align-items: center;
    font-size: 1.16em;
    color: #F6F7FC;
}

div[aria-details="added"] {
    color: #05FF00;
}

div[aria-details="subtracted"] {
    color: #FF1B1B;
}

.valuation-bottom {
    font-size: 1.16em;
    color: white;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 7;
    width: 100%;
    justify-content: space-around;
}

.character {
    display: flex;
    align-items: center;
    flex: 4;
    width: 85%;
}

.character-img {
    display: flex;
    align-items: center;
    height: 21.3em;
    width: 21.3em;
}

.character-right {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
}

.loader {
    display: flex;
    flex: 1;
    padding: 3%;
    justify-content: flex-end;
}

.character-details {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.character-name {
    display: flex;
    font-weight: bold;
    font-size: 2.33em;
    padding: 2% 0 2% 8%;
}

.character-job-title {
    display: flex;
    margin: 5%;
    background: #BACEDB;
    color: white;
    align-items: center;
    border-radius: 10px;
    padding: 2% 0 2% 8%;
    font-weight: 500;
    font-size: 1.6em;
}

.question {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    flex: 2;
    background: #02CC9A;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    font-size: 1.6em;
}

div[aria-selected] {
    background: #414C96;
    color: white;
}

.answers {
    display: flex;
    width: 85%;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.answer {
    cursor: pointer;
    display: flex;
    padding: 5px;
    margin: 5px;
    width: 100%;
    border: 1px solid #D1D1D1;
    border-radius: 10px;
    justify-content: center;
    font-size: 1.3em;
    text-align: center;
}

.answer:hover {
    border: 1px solid #414C96;
}

.progress {
    cursor: progress;
}

.bottom-buttons {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #172950;
    border-radius: 32px
}

.bottom-item {
    cursor: pointer;
    height: 3.5em;
    width: 3.5em;
    margin: 0 3.5em 0 3.5em;
}


@media screen and (max-height: 960px) and (min-height: 861px) {
    .game {
        height: 760px;
        max-width: 540px;
        min-width: 440px;
        width: 100%;
    }

    #root {
        font-size: 12px;
    }
}

@media screen and (max-height: 860px) and (min-height: 761px) {
    .game {
        height: 670px;
        max-width: 480px;
        min-width: 380px;
        width: 100%;
    }

    #root {
        font-size: 11px;
    }
}

@media screen and (max-height: 760px) and (min-height: 661px) {
    .game {
        height: 660px;
        max-width: 420px;
        min-width: 320px;
        width: 100%;
    }

    #root {
        font-size: 9px;
    }
}

@media screen and (max-height: 660px) and (min-height: 561px) {
    .game {
        height: 560px;
        max-width: 350px;
        min-width: 250px;
        width: 100%;
    }

    #root {
        font-size: 8px;
    }
}

@media screen and (max-height: 560px) and (min-height: 461px) {
    .game {
        height: 460px;
        max-width: 290px;
        min-width: 190px;
        width: 100%;
    }

    #root {
        font-size: 6px;
    }
}

@media screen and (max-width: 500px) {
    #bg-video {
        display: none;
    }

    .app {
        background: rgba(65, 76, 150, 100);
    }
}

.unselectable {
    user-select: none;
}
